*, *:before, *:after {
    box-sizing: border-box; }

.hidden {
    display: none; }

body, html {
    background-color: $white;
    color: $text-color; }

.cover-img {
    display: block;
    height: 100%;
    object-fit: cover;
    width: 100%; }

.center-content {
    align-items: center;
    display: flex;
    justify-content: center;
    img {
        height: auto;
        max-width: 100%; } }
