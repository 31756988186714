footer {
    margin-top: 100px;
    background: #000000;
    color: #FFFFFF;
    p {
        font-size: px2rem(20);
        line-height: px2rem(30); }
    li {
        font-size: px2rem(14); }
    img {
        max-width: 100%; }
    .logo {
        text-align: right;
        font-size: px2rem(16);
        white-space: nowrap; } }
