.reading-progress {
    bottom: 0;
    font-weight: 500;
    height: 30px;
    left: 0;
    position: sticky;
    right: 0;
    top: calc(100% - 30px);
    z-index: 10;
    &__bar {
        background: $back;
        bottom: 0;
        height: 100%;
        left: 0;
        height: 4px;
        max-width: 100%;
        position: absolute; }
    p {
        bottom: 4px;
        color: $back;
        font-size: px2rem(12);
        margin-left: 4px;
        position: absolute;
        right: 4px;
        text-align: left;
        text-transform: uppercase;
        white-space: nowrap;
        span {
            display: inline-block;
            min-width: 32px; } } }
