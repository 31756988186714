.page {
    &__header {
        background-color: $back;
        color: $front;
        font-weight: 600;
        overflow: hidden;
        padding: 250px 10px 100px;
        position: relative;
        text-align: left;
        text-shadow: 0 0 20px rgba($front, 0.5);
        #circuit {
            -webkit-mask-image: -webkit-gradient(linear, left 70%, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
            height: 100%;
            left: 0;
            opacity: 0.25;
            position: absolute;
            top: 0;
            width: 100%; }
        .glitch {
            color: $front;
            &:after, &:before {
                background: none;
                color: $front; } } }
    .text {
        h2 {
            position: relative; } } }

