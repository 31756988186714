//
// Stretched link
//

.stretched-link {
    &::before {
        bottom: 0;
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1; } }
