@mixin abs-fill {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0; }

@mixin disable-selection {
    user-select: none;
    * {
        user-select: none; } }

@mixin center-content {
    align-items: center;
    display: flex;
    justify-content: center; }

@mixin mobile {
    @media(max-width: 768px) {
        @content; } }

@mixin tablet {
    @media(max-width: 1024px) {
        @content; } }

@mixin huge {
    @media(min-width: 1600px) {
        @content; } }

@mixin text-shadow($color: $front) {
    text-shadow: 0 0 7px rgba($color, 0.5); }


@keyframes link-underline {
    0% {
        transform: scaleX(0);
        transform-origin: 0 center; }
    10% {
        transform: scaleX(1);
        transform-origin: 0 center; }
    80% {
        transform: scaleX(1);
        transform-origin: right center; }
    90%, 100% {
        transform: scaleX(0);
        transform-origin: right center; } }

@mixin link {
    display: inline-block;
    vertical-align: middle;
    &::after {
        background: $back;
        content: '';
        display: block;
        height: 1px;
        margin-top: 3px;
        width: 100%; }
    @for $i from 1 through 30 {
        &:nth-child(#{$i}) {
            &:after {
                animation: link-underline 3s #{$i / 3}s infinite ease-in-out; } } } }
