header {
    color: $white;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 100;
    nav {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding-top: 10px;
        position: relative; }
    .logo {
        svg {
            height: 80px;
            margin-right: 20px;
            @include media-breakpoint-up(md) {
                height: auto;
                width: 85px; } } }
    .mobile_button {
        color: inherit;
        display: none;
        svg {
            height: 20px;
            width: 20px;
            * {
                fill: $front; } } }
    #menu {
        li {
            display: inline-block;
            font-size: px2rem(14);
            margin: 0 10px;
            text-transform: uppercase;
            vertical-align: middle;
            &:last-child {
                margin-right: 0; }
            span, a {
                letter-spacing: 0.03em; }
            a {
                color: inherit;
                display: block;
                text-decoration: none;
                &::after, &::before {
                    background: $back;
                    color: $white; }
                &.active {
                    border-bottom: 1px solid $white; } }
            #switch-colors {
                cursor: pointer;
                display: none; } } }


    @include media-breakpoint-down(md) {
        .mobile_button {
            display: inline-block;
            height: 65px;
            line-height: 65px;
            padding: 0;
            text-align: center;
            text-decoration: none;
            text-transform: uppercase;
            width: 65px;
            svg {
                display: inline-block;
                vertical-align: middle; }
            &__close {
                position: absolute;
                right: 14px;
                top: 10px; } }

        .logo {
            svg {
                height: 50px;
                margin-right: 0; } }
        #menu {
            background: $back;
            bottom: 0;
            display: none;
            left: 0;
            padding-top: 100px;
            padding-left: 76px;
            position: fixed;
            right: 0;
            top: 0;
            &:target {
                display: block; }
            ul {
                li {
                    display: block;
                    margin-bottom: 30px;
                    a, span {
                        display: inline-block; } }
                &::after {
                    content: none; } } } } }

.search {
    background: rgba($back, 0.5);
    color: $text-color;
    display: none;
    height: 100%;
    left: 0;
    overflow: hidden;
    padding-top: 15%;
    position: fixed;
    top: 0;
    transition: width 0.33s;
    width: 100%;
    z-index: 20;
    &__overlay {
        cursor: pointer;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%; }
    &__body {
        background: $front;
        margin: auto;
        max-width: 700px;
        padding: 30px;
        position: relative;
        .close {
            cursor: pointer;
            height: 20px;
            position: absolute;
            right: 20px;
            top: 20px;
            width: 20px; } }
    &__results {
        max-height: 50vh;
        overflow-y: auto; }
    .search-opened & {
        display: block; }
    input {
        -webkit-appearance: none;
        background: transparent;
        border: 0;
        border-bottom: 1px solid $text-color;
        border-radius: 0;
        color: $text-color;
        display: block;
        font-family: $font;
        font-size: 40px;
        font-weight: 300;
        padding: 15px;
        padding-left: 0;
        width: 100%;
        &:focus-visible {
            outline: 0; } }
    ul {
        padding-top: 10px; }
    li {
        font-size: 20px;
        padding-bottom: 20px;
        small {
            display: inline-block;
            font-size: 70%;
            vertical-align: top; } } }

.search-opened {
    .search-icon {
        .open {
            display: none; }
        .close {
            display: inline-block; } } }
