.quote {
    align-items: center;
    display: block;
    padding-bottom: 150px;
    padding-top: 150px;
    position: relative;
    text-align: center;
    position: relative;
    q {
        color: $back;
        display: block;
        font-size: px2rem(40);
        line-height: px2rem(52);
        position: relative;
        @include media-breakpoint-down(md) {
            font-size: px2rem(30);
            line-height: px2rem(46); }
        &:after, &:before {
            background: white;
            color: $back; } }
    span {
        display: block;
        font-size: px2rem(180);
        left: 0;
        position: absolute;
        right: 0;
        text-align: center;
        @include media-breakpoint-down(md) {
            font-size: px2rem(100); }
        &:first-child {
            top: -10px;
            @include media-breakpoint-down(md) {
                top: 40px; } }
        &:last-child {
            bottom: -130px;
            @include media-breakpoint-down(md) {
                bottom: -30px; } } } }
