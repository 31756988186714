html {
    -ms-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: 100%; }

body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: $font;
    font-weight: 300;
    letter-spacing: 0.01em;
    text-rendering: geometricPrecision; }

p, li {
    font-size: px2rem(16);
    line-height: 1.6333333333; }

h1, .h1 {
    font-size: px2rem(56);
    font-weight: 600;
    line-height: px2rem(65);
    margin-bottom: px2rem(20);
    @include mobile {
        font-size: px2rem(36);
        line-height: px2rem(42); } }

h2, .h2 {
    font-size: px2rem(32);
    line-height: px2rem(40);
    margin-bottom: px2rem(20);
    @include mobile {
        font-size: px2rem(30); } }

h3, .h3 {
    font-size: px2rem(26);
    font-weight: 500;
    line-height: px2rem(32);
    margin-bottom: px2rem(35);
    @include media-breakpoint-down(md) {
        font-size: px2rem(20);
        line-height: px2rem(28);
        margin-bottom: px2rem(20); } }


h4, .h4 {
    font-size: px2rem(22);
    letter-spacing: 0.02em;
    line-height: px2rem(22);
    margin-bottom: px2rem(15);
    @include mobile {
        font-size: px2rem(18); } }

.link {
    position: relative;
    transition: color .22s $easeInOutCubic;
    z-index: 1;
    &::after {
        background: $front;
        bottom: 0;
        content: '';
        display: block;
        height: 0;
        left: 0;
        position: absolute;
        transition: height .22s $easeInOutCubic;
        width: 100%;
        z-index: -1; }
    &:hover {
        color: $back;
        &::after {
            height: 100%; } } }
a {
    color: inherit;
    text-decoration: none; }

.text {
    a {
        @include link;
        color: $back;
        word-break: break-all; }
    img, iframe, video {
        display: block;
        margin: 10px 0;
        max-width: 100%; }
    img {
        height: auto; }
    strong {
        font-weight: 500; }
    em {
        font-style: italic; }
    p {
        padding-bottom: 20px;
        &:last-child {
            padding-bottom: 0;
            img, iframe, video {
                margin-bottom: 0; } } }
    ul {
        li {
            margin-bottom: 5px;
            padding-left: 10px;
            position: relative;
            &::before {
                content: '- ';
                display: inline-block;
                margin-left: -10px;
                margin-right: 5px; } } }
    &-end {
        text-align: right; } }


small, .small {
    color: #555555;
    display: block;
    font-size: px2rem(12);
    margin-bottom: 10px;
    margin-top: 10px; }

.summary {
    font-size: px2rem(26);
    line-height: px2rem(40); }

.aside-img {
    margin-top: 30px;
    @include media-breakpoint-up(md) {
        margin-top: 64px; }
    &__content {
        position: sticky;
        top: 64px; } }

.indent-list {
    ul {
        li {
            margin-bottom: 20px;
            padding-left: 50px;
            text-indent: -50px;
            a {
                display: inline;
                text-decoration: underline;
                vertical-align: inherit;
                &::after {
                    display: none; } }
            &::before {
                display: none; } } } }

.sticky-title {
    @include media-breakpoint-up(md) {
        align-self: start;
        bottom: 0;
        position: sticky;
        top: 30px; } }
