article {
    position: relative;
    h3 {
        margin-bottom: 10px; }
    time {
        color: #555555;
        display: block;
        font-size: px2rem(12);
        margin-bottom: 10px;
        margin-top: 10px; }
    @include media-breakpoint-up(md) {
        .thumbnail {
            height: 270px;
            &--big {
                height: 350px; } } } }

.post-technic {
    position: relative;
    &__index {
        border-bottom: 1px solid $back;
        font-size: px2rem(14);
        margin-bottom: 10px;
        padding-bottom: 10px; }
    &__title {
        padding: 10px 0 20px;
        h2 {
            @include media-breakpoint-up(md) {
                font-size: px2rem(35); }
            font-size: px2rem(25);
            position: relative; } }
    &__text {
        background: $white;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        top: 100%;
        z-index: 10;
        p {
            padding-bottom: 15px; } }
    &:hover {
        .post-technic__text {
            opacity: 1; } } }


.partner {
    align-items: center;
    display: flex;
    justify-content: center;
    img {
        display: block;
        max-width: 100%;
        width: 100px; } }
