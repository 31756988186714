$steps: 15;

@keyframes noise-anim {
    0% {
        clip: rect(0,9999px,0,0); }
    74% {
        clip: rect(0,9999px,0,0); }
    @for $i from 0 through $steps {
        #{percentage($i*(1/$steps)/4+0.75)} {
            clip: rect(random(100)+px,9999px,random(200)+px,0); } } }

@keyframes noise-anim-2 {
    0% {
        clip: rect(0,9999px,0,0); }
    74% {
        clip: rect(0,9999px,0,0); }
    @for $i from 0 through $steps {
        #{percentage($i*(1/$steps)/4+0.75)} {
            clip: rect(random(100)+px,9999px,random(200)+px,0); } } }

@keyframes noise-anim-continuous {
    0% {
        clip: rect(0,9999px,0,0); }
    74% {
        clip: rect(0,9999px,0,0); }
    @for $i from 0 through $steps {
        #{percentage($i*(1/$steps))} {
            clip: rect(random(100)+px,9999px,random(200)+px,0); } } }
@keyframes noise-anim-2-continuous {
    0% {
        clip: rect(0,9999px,0,0); }
    74% {
        clip: rect(0,9999px,0,0); }
    @for $i from 0 through $steps {
        #{percentage($i*(1/$steps)/4)} {
            clip: rect(random(100)+px,9999px,random(200)+px,0); } } }

.glitch {
    color: $white;
    display: table;
    position: relative;
    &:after, &:before {
        background: $back;
        clip: rect(0,900px,0,0);
        color: $white;
        content: attr(data-text);
        height: 100%;
        overflow: hidden;
        position: absolute;
        top: 0;
        width: 100%; } }

.glitch:after {
    animation: noise-anim 4s infinite linear alternate-reverse;
    left: 2px;
    text-shadow: -1px 0 red; }


.glitch:before {
    animation: noise-anim-2 4s infinite linear alternate-reverse;
    left: -2px;
    text-shadow: 1px 0 blue; }

.glitch--hovered {
    &:after, &::before {
        animation: none; }
    &:hover {
        &:before {
            animation: noise-anim-2-continuous 2s infinite linear alternate-reverse; }
        &:after {
            animation: noise-anim-continuous 2s infinite linear alternate-reverse; } } }
.glitch--white {
    color: $text-color;
    &:after, &:before {
        background: $white;
        color: $text-color; } }

@keyframes noise-bg {
    0% {
        background-position: 0 0; }
    100% {
        background-position: -50% 100%; } }

body {
    &.is-scrolling {
        animation: noise-bg 1s infinite linear;
        background-image: url(/assets/images/noise_hard.png); } }
