@font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/Oswald-Light.woff2') format('woff2'), url('../fonts/Oswald-Light.woff') format('woff'); }


@font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/Oswald-Medium.woff2') format('woff2'), url('../fonts/Oswald-Medium.woff') format('woff'); }

@font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/Oswald-SemiBold.woff2') format('woff2'), url('../fonts/Oswald-SemiBold.woff') format('woff'); }
