img {
    height: auto; }

@keyframes pix-glitch {
    0%, 20% {
        display: block;
        filter: saturate(0);
        opacity: 1;
 }        // transform: translate(2px, -1px)
    10%, 30% {
        display: block;
        filter: saturate(8);
        opacity: 1;
 }        // transform: translate(0px, -2px)
    31%, 73% {
        opacity: 0; }
    74% {
        opacity: 1; }
    81% {
        opacity: 0.5; }
    85% {
        filter: saturate(8);
        opacity: 1; }
    88% {
        filter: saturate(5) grayscale(1);
        opacity: 1; }
    100% {
        filter: saturate(1); } }


@keyframes pix-glitch-light {
    0% {
        image-rendering: crisp-edges; }
    50% {
        image-rendering: pixelated; }
    100% {
        image-rendering: auto; } }

.pixel-image {
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
    img {
        display: block;
        height: 100%;
        image-rendering: pixelated;
        object-fit: cover;
        width: 100%;
        &.original {
            display: none;
            left: 0;
            position: absolute;
            top: 0;
            z-index: 1; } }
    &:hover {
        .original {
            animation: pix-glitch 0.25s steps(2);
            display: block; } } }

article:hover {
    .original {
        animation: pix-glitch 0.25s steps(2);
        display: block; } }

.img-fluid {
    height: auto;
    max-width: 100%; }
